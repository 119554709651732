import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentGatewayService } from 'src/app/core/services/payment-gateway.service';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-transaction-success',
  templateUrl: './transaction-success.component.html',
  styleUrls: ['./transaction-success.component.scss']
})
export class TransactionSuccessComponent implements OnInit {

  constructor(private route: ActivatedRoute, private paymentGatewayService: PaymentGatewayService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const sut = params['sut']; 
      const ref = params['ref'];

      this.paymentGatewayService.paymentSuccessful(sut, ref).subscribe({
        next: response => {
          console.log(response);
          this.router.navigate(['/wallet/home/topup']);
        },
        error: error => {
          console.error(error);
          setTimeout(() => {
            this.router.navigate(['/wallet/home/topup']); // Change to your desired error page route
          }, 3000);
          return throwError(error);
        }
      });
    });
  }
}
