export const environment = {
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyCmanPoSzzcYHiooYYuQyS-QWFoP7B_LsU",
      authDomain: "dynamic-product-apptunix.firebaseapp.com",
      projectId: "dynamic-product-apptunix",
      storageBucket: "dynamic-product-apptunix.appspot.com",
      messagingSenderId: "943877095356",
      appId: "1:943877095356:web:a2e8c73ef8599e66eb75b9",
      measurementId: "G-KQY0DL8V04",
    },
    baseUrl: "https://api.dev.zonedeliveryservices.com",
    commonurl: "/common/v2",
    ondemandUrl: "/ondemand/v2",
    socketUrl: "https://apiondemand.dev.zonedeliveryservices.com/",
    apiKey: "AIzaSyCsPriWr1muoozMnLsy5bFQRgLlZ88bZfU",
    moduleKey: "onDemand_pharmacy_2",
    appId: "lfzyulw7hm4qitee9h13pr50"
    // appId: 'lgkynl8evy1xkq95ofpsgaex',
    // moduleKey: 'onDemand_foodDeliveryApp_2'    
  };