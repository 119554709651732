import { Injectable } from "@angular/core";
import { ENTITY_TYPE } from "../interfaces-types/custom-enums";
import { DataSharingService } from "../services/data-sharing.service";
import { HttpService } from "../services/http.service";
import { coreUrlList } from "../urlList/coreUrlList";

@Injectable()
export class WalletMethods {
    constructor(   
        private api: HttpService,
        private urlList: coreUrlList,
        private dataSharingService: DataSharingService
        ) {

    }

    async getSetWallet(storeDetails:object) {
        try {
          const request:object = {
            'entityType': ENTITY_TYPE.MERCHANT,
            'entityId': storeDetails['_id'],
            'moduleKey': storeDetails['moduleKey']
          }
      
          const apiResponse:object = await this.api.post(this.urlList.getSetWallet,request).toPromise()
          if(apiResponse ) {
            console.info('Api response after getting/setting wallet is: ', apiResponse);
            const {status , success}: {status: number, success: boolean} = apiResponse['response'];
            if(status === 200 && success === true){
              this.dataSharingService.setWalletData(apiResponse['data']);
            }
          }
        } catch (error) {
          console.error('Error caught while getting wallet data: ', error);
        }
      }
}