import { Injectable, Inject, HostBinding } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Injectable({
 providedIn: "root",
})
export class InitialService {
 constructor(
   private http: HttpClient,
   @Inject(DOCUMENT) private _document: HTMLDocument,
   private titleService: Title
 ) {}
 api = environment.baseUrl + environment.commonurl +"/admin/greatSetting";
 appSpecificSettingApi =
 environment.baseUrl + environment.commonurl +"/admin/getAppSetting";
 projectSettings: any;
 greatSettings: any;
 appSettings = new Subject();


 async load() {
   let fullUrl = window.location.href;


   // For static Builds
   this.api = environment.baseUrl + environment.commonurl +"/admin/greatSetting";


   await this.getGreatSettings();
   this.subscribeToAppSettings();
 }


 subscribeToAppSettings() {
   this.appSettings.subscribe((res) => {
     if (res) {
       this.projectSettings = res;
       this.setData();
     }
   });
 }


 async getGreatSettings() {
   try {
     let resp = await this.http.get(this.api).toPromise();
     console.log(
       resp["data"]["appType"].map((item) => item.merchantPanel).includes(true)
     );


     if (
       resp["response"]["success"] &&
       resp["data"].isActive &&
       (resp["data"]["merchantPanel"] ||
         resp["data"]["appType"]
           .map((item) => item.merchantPanel)
           .includes(true))
     ) {
       this.greatSettings = resp["data"];
       // Only Use Modules which are active
       this.greatSettings.appType = this.greatSettings.appType.filter(
         (appType) => appType.status == true
       );
       // Set On localstorage for global access
       localStorage.setItem(
         "greatSettings",
         JSON.stringify(this.greatSettings)
       );
       localStorage.setItem("appId", this.greatSettings["appId"]);
       // Set project Base Url
       // environment.baseUrl = this.greatSettings.baseURL + "/v1";
       this.loadAppSpecificSettings();
       return;
     } else {
       alert(`Failure From Great Admin !- ${resp["message"]}`);
     }
   } catch (err) {
     alert(`Failure From Great Admin #- ${JSON.stringify(err)}`);
   }
 }


 async loadAppSpecificSettings() {
   this.http.get(this.appSpecificSettingApi).subscribe((resp) => {
     if (resp["response"]["success"]) {
       this.projectSettings = resp["data"];
       this.setData();
     }
   });
 }


 setData() {
   // Set On localstorage for global access
   localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
   // Set Global Color
   if (this.projectSettings && this.projectSettings.colorCodes) {
     document.documentElement.style.setProperty(
       "--primary-color",
       this.projectSettings.colorCodes
     );
   }
   // Set Favicon
   if (this.projectSettings && this.projectSettings.favIcon) {
     this._document
       .getElementById("appFavicon")
       .setAttribute("href", this.projectSettings.favIcon);
   }
   // Set Title
   if (this.projectSettings && this.projectSettings.appName) {
     this.titleService.setTitle(
       this.projectSettings.appName + " - Merchant Panel"
     );
   }
 }
 public getBaseUrl() {
   if (this.greatSettings) {
     const baseUrl = environment.baseUrl;
     return {
       baseUrl: baseUrl,
       imageUrl: environment.baseUrl,
     };
   }
 }
}
