import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ng6-toastr-notifications";
import { AngularWebStorageModule } from "angular-web-storage";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DatePipe } from "@angular/common";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MaterialModule } from "./modules/material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { AuthGuard } from "./core/guards/auth.guard";
import { InitialService } from "./core/services/initial.service";
import { ForgotPasswordModalComponent } from "./modules/auth/forgot-password-modal/forgot-password-modal.component";
import { VerificationTypeComponent } from "./modules/auth/signup/verification-type/verification-type.component";
import { AcceptModalComponent } from "./modules/main/store/order/accept-modal/accept-modal.component";
import { StoreOutletmodalComponent } from "./modules/main/store/store/storeOutletModal/storeOutletModal.component";
import { coreUrlList } from "./core/urlList/coreUrlList";
import { SetInterceptorService } from "./core/interceptors/set-interceptor.service";
import { GetInterceptorService } from "./core/interceptors/get-interceptor.service";
import { ImportProductsModalComponent } from "./modules/main/add-properties/products/import-products-modal/import-products-modal.component";
import { SocketService } from "./core/services/socket.service";
import { CoreModule } from "./core/core.module";
import { TransactionSuccessComponent } from './modules/transaction-success/transaction-success.component';
import { TransactionCancelComponent } from './modules/transaction-cancel/transaction-cancel.component';


export function startupServiceFactory(
 InitialService: InitialService
): Function {
 return () => InitialService.load();
}
export function countdownConfigFactory() {
 return { format: `dd:hh:mm:ss` };
}


@NgModule({
    declarations: [
        AppComponent,
        AcceptModalComponent,
        StoreOutletmodalComponent,
        ForgotPasswordModalComponent,
        VerificationTypeComponent,
        ImportProductsModalComponent,
        TransactionSuccessComponent,
        TransactionCancelComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        FormsModule,
        AngularWebStorageModule,
        HttpClientModule,
        NgxSpinnerModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AgmDirectionModule,
        GooglePlaceModule,
        CoreModule,
        AgmCoreModule.forRoot({
            apiKey: environment.apiKey,
            libraries: ["geometry", "places"],
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            registrationStrategy: "registerImmediately",
        }),
    ],
    providers: [
        coreUrlList,
        InitialService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [InitialService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GetInterceptorService,
            multi: true,
        },
        AuthGuard,
        SocketService,
        NgxSpinnerService,
        DatePipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


