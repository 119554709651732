import { Injectable } from '@angular/core';
import {HttpClient , HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {


  constructor(private http: HttpClient ) { }
  
  getAccessToken(amountToTopup:number , serviceChargesAmount: number , totalAmountToPay: number): Observable<any>{


   const merchantInfo = JSON.parse(localStorage.getItem("storeLogin"));
   

   const data = {
    id : merchantInfo._id,
    authToken : merchantInfo.authToken,
    amountToTopup ,
    totalAmountToPay,
    serviceChargesAmount
   }

      //TODO: ADD AUTH i.e merchant token-> issue throwing error Invalid Algo 
      return this.http.post<any>(`${environment.baseUrl}${environment.ondemandUrl}/wallet/bankTransferAccessToken` , {data , merchantInfo });
  }





//sut : SUCCESS URL TOKEN
  paymentSuccessful(sut: string , ref: string){


    const merchantInfo = JSON.parse(localStorage.getItem("storeLogin"));

    const data={
      merchantInfo,
      sut: sut,
      ref: ref
    }
    return this.http.post<any>(`${environment.baseUrl}${environment.ondemandUrl}/wallet/successTransaction` , {data});
  }



  // pending payment 

  pendingPayment(refrenceCode :string ){
    console.log(refrenceCode);
    const data = {ref: refrenceCode}
    return this.http.post<any>(`${environment.baseUrl}${environment.ondemandUrl}/wallet/resolvePendingTransaction` , {data});
  }


  getServiceCharges(){
    return this.http.get<any>(`${environment.baseUrl}${environment.ondemandUrl}/wallet/getServiceCharges`);
  }



}
