import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router  } from '@angular/router';

@Component({
  selector: 'app-transaction-cancel',
  templateUrl: './transaction-cancel.component.html',
  styleUrls: ['./transaction-cancel.component.scss']
})
export class TransactionCancelComponent implements OnInit {

  constructor(private router: Router) { }

 ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/wallet/home/topup']); 
    }, 3000);
  }

}
