import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, finalize, tap } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class GetInterceptorService {
  constructor(
    private toaster: ToastrManager,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          // this.logOut();
        }
        // if (error.status == 401) {
        //   this.logOut();
        //   // 401 handled in auth.interceptor
        //   // this.commonService.presentsToast('error','top-end','you are logout for security purpose.');
        // }
        return throwError(error);
      }),
      finalize(() => {
        this.spinner.hide();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (
              eventRes.body &&
              eventRes.body.response &&
              !eventRes.body.response.success
            ) {
              this.toaster.errorToastr(eventRes.body.response.message);
            }
          }
        },
        (error) => {
          console.log(error);

          // this.toaster.errorToastr(error.message);
          switch (error.status) {
            // case 400:
            /*** If api responds with bad request  ** */
            // this.toaster.errorToastr(error.message);
            // this.toaster.errorToastr("Api Not Working");
            // break;
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.toaster.errorToastr(
                "You have been logged out for security purpose."
              );
              this.logOut();
              break;
            case 500:
              // /*** If api does not respond  ** */
              this.toaster.errorToastr(error.statusText);
              // this.toaster.errorToastr("Api Not Working");
              break;
            // case 404:
            /*** If api does not respond  ** */
            // this.toaster.errorToastr(error.message);
            // this.toaster.errorToastr("Api Not Found!");
            // break;
            default:
              // if (error.error && error.error.message ) {
              //     this.toaster.errorToastr(error.error.message);
              // } else if(error.message) {
              //     this.toaster.errorToastr(error.message);
              // }

              if (
                error.error &&
                error.error.response &&
                error.error.response.message
              ) {
                this.toaster.errorToastr(error.error.response.message);
              }
          }
        }
      )
    );
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    let appId = localStorage.getItem("appId");
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    localStorage.setItem("appId", appId);
    this.router.navigateByUrl("/auth/login");
  }
}
