import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) { }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();
    return next.handle(clonedReq);
  }


  handleRequest(req: HttpRequest<any>) {
    let fullUrl = window.location.href;
    let appId = environment.appId;
    let token = "";
    let geofenceId = "NA";


    if (localStorage.getItem("merchantToken")) {
      token = localStorage.getItem("merchantToken");
    }


    if (localStorage.getItem("geofenceId")) {
      geofenceId = localStorage.getItem("geofenceId");
    }


    let authReq;


    authReq = req.clone({
      headers: new HttpHeaders({
        Authorization: token,
        geofenceid: geofenceId,
        appid: appId,
      }),
    });


    return authReq;
  }
}
