import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { ImageFilterPipe } from "./pipes/image-filter.pipe";
import { appNoSpaceAtFirst } from "./directives/no-space.directive";
import { WalletMethods } from "./helper-methods/wallet-methods";
import { UtcToUaePipe } from "./pipes/timezoneConversion";

@NgModule({
  declarations: [NumberOnlyDirective, ImageFilterPipe, UtcToUaePipe, appNoSpaceAtFirst],
  providers: [WalletMethods],
  imports: [CommonModule],
  exports: [NumberOnlyDirective, ImageFilterPipe, UtcToUaePipe, appNoSpaceAtFirst],
})
export class CoreModule { }
